<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>Organization</strong>
            <small>Form</small>
          </div>

          <b-form v-on:submit.prevent.self="submit()">
            <b-row>
              <b-col sm="6">
                <b-form-group>
                  <label for="title">
                    Title
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="text"
                    required
                    v-model="organization.OrganizationName"
                    id="title"
                    placeholder="Organization Name"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group>
                  <label for="type">
                    Organization Type
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="text"
                    required
                    v-model="organization.OrganizationType"
                    id="type"
                    placeholder="Organization Type"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <b-form-group>
                  <label for="config_id">
                    Config ID
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    v-on:keyup.native="onKeyHandler('ConfigID')"
                     type="number" step="any"
                    required
                    v-model="organization.ConfigID"
                    id="config-id"
                    placeholder="Configuration ID"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group>
                  <label for="status">Status</label>
                  <br />
                  <c-switch
                    class="mx-1"
                    color="primary"
                    v-model="organization.OrgIsActive"
                    defaultChecked
                    variant="3d"
                    label
                    v-bind="labelIcon"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="6">
                <b-form-group>
                  <label for="logo">
                    Logo
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-file
                    id="fileInput"
                    v-on:change="uploadLogo($event.target)"
                    ref="fileinput"
                    :required="organization.OrganizationID == null ? true : false"
                    accept="image/jpeg, image/png, image/gif"
                    :plain="true"
                  ></b-form-file>
                  <input type="hidden" v-model="organization.Logo" />
                </b-form-group>
              </b-col>

              <b-col sm="6" id="preview">
                <img
                  v-if="organization.Logo != ''"
                  :src="BaseURL+organization.Logo"
                  class="img-fluid"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="6">
                <b-form-group>
                  <label for="logo">
                    Organization Image
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-file
                    id="fileInput"
                    v-on:change="uploadOrganizationImage($event.target)"
                    ref="fileinput"
                    :required="organization.OrganizationID == null ? true : false"
                    accept="image/jpeg, image/png, image/gif"
                    :plain="true"
                  ></b-form-file>
                </b-form-group>
              </b-col>

              <b-col sm="6" id="preview">
                <img
                  v-if="organization.OrganizationImage != ''"
                  :src="BaseURL+organization.OrganizationImage"
                  class="img-fluid"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <b-button type="submit" size="sm" variant="primary">
                  <i class="fa fa-dot-circle-o"></i>
                  {{ commontext.submitButton }}
                </b-button>
              </b-col>
              <b-col sm="6">
                <b-button @click="resetForm" size="sm" variant="success">
                  <i class="fa fa-dot-circle-o"></i> Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="ModalConfirmationOnSubmit"
      ref="ModalConfirmationOnSubmit"
      @ok="handleModalOkay"
      :title="modalObject.title"
      class="center-footer"
      footer-class="justify-content-center border-0"
      cancel-variant="outline-danger"
      ok-variant="outline-success"
      centered
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <div>{{ modalObject.message }}</div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { Switch as cSwitch } from "@coreui/vue";
import OrganizationService from "@/services/OrganizationService";
import CinemaService from "@/services/CinemaService";
import UploadService from "@/services/UploadService";

export default {
  name: "AddOrganization",
  components: {
    cSwitch
  },
  data() {
    return {
      BaseURL: "",
      commontext: {
        submitButton: "Submit"
      },
      organization: {
        OrganizationID: null,
        OrganizationName: "",
        OrganizationType: "",
        OrganizationImage: "",
        ConfigID: "",
        Flag: 1,
        Logo: "",
        OrgIsActive: true
      },
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      }
    };
  },
  mounted() {
    const { orgID } = this.$route.query;
    if (orgID) {
      this.getOrgByID(orgID);
    }
  },
  methods: {
    getOrgByID: function(OrganizationID) {
      let payload = {
        OrganizationID
      };
      OrganizationService.getOrgByID(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.OrganizationList.length > 0) {
            this.commontext.submitButton = "Update";
            this.organization = data.OrganizationList[0];
            this.organization.Flag = 2;
            this.BaseURL = data.OrganizationList[0].BaseURL;
            // let previewDiv = document.getElementById("preview");
            // while (previewDiv.firstChild) {
            //   previewDiv.removeChild(previewDiv.firstChild);
            // }
            // var previewImg = document.createElement("img");
            // previewDiv.appendChild(previewImg);
            // previewImg.src =
            //   data.OrganizationList[0].BaseURL + data.OrganizationList[0].Logo;
            // previewImg.classList.add("img-fluid");
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("Catch on AddOrganization-getOrgByID Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    resetForm: function() {
      this.commontext.submitButton = "Submit";
      this.organization = {
        OrganizationID: null,
        OrganizationName: "",
        OrganizationType: "",
        Flag: 1,
        ConfigID: "",
        Logo: "",
        OrgIsActive: true
      };
      this.$refs.fileinput.reset();
      this.BaseURL = "";
      // let previewDiv = document.getElementById("preview");
      // while (previewDiv.firstChild) {
      //   previewDiv.removeChild(previewDiv.firstChild);
      // }
      window.location.replace(window.location.href.split("?")[0]);
    },
    uploadOrganizationImage: function(image) {
      //OrganizationImage
      var formdata = new FormData();

      var file = image.files[0];

      formdata.append("assets/images/organizationImage", image.files[0]);
      formdata.append("rename", this.organization.OrganizationName);
      // let res = await CinemaService.uploadFiles(formdata);
      UploadService.uploadFiles(formdata)
        .then(response => {
          const { data } = response;
          data.Message ? this.showMessageSnackbar(data.Message) : "";
          if (data && data.Status) {
            this.organization.OrganizationImage = data.Path;
            this.BaseURL = data.BaseURL;
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    uploadLogo: function(image) {
      var formdata = new FormData();

      var file = image.files[0];

      formdata.append("assets/images/organization", image.files[0]);
      formdata.append("rename", this.organization.OrganizationName);
      // let res = await CinemaService.uploadFiles(formdata);
      UploadService.uploadFiles(formdata)
        .then(response => {
          const { data } = response;
          data.Message ? this.showMessageSnackbar(data.Message) : "";
          if (data && data.Status) {
            this.organization.Logo = data.Path;
            this.BaseURL = data.BaseURL;
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // uploadLogo: function(image) {
    //   var formdata = new FormData();

    //   var file = image.files[0];

    //   formdata.append("assets/images/organization", image.files[0]);
    //   formdata.append("rename", this.organization.OrganizationName);
    //   // let res = await CinemaService.uploadFiles(formdata);
    //   UploadService.uploadFiles(formdata)
    //     .then(response => {
    //       const { data } = response;
    //       this.showMessageSnackbar(
    //         data.Message || "Oops! Something went wrong."
    //       );
    //       if (data && data.Status) {
    //         this.organization.Logo = data.Path;
    //         var previewDiv = document.getElementById("preview");
    //         var previewImg = "";
    //         var previewImg = document.createElement("img");
    //         while (previewDiv.firstChild) {
    //           previewDiv.removeChild(previewDiv.firstChild);
    //         }
    //         previewDiv.appendChild(previewImg);
    //         previewImg.src = window.URL.createObjectURL(image.files[0]);
    //         previewImg.classList.add("img-fluid");
    //       }
    //     })
    //     .catch(error => {
    //       this.showMessageSnackbar(
    //         "Oops! Something went wrong. kindly contact your administrator"
    //       );
    //     });
    // },
    onKeyHandler: function(name) {
      if (name == "ConfigID") {
        this.organization.ConfigID = this.organization.ConfigID.slice(0, 10);
      }
    },
    validation: function() {
      let OrganizationNameRef = /(?=.{4,})/;
      if (!OrganizationNameRef.test(this.organization.OrganizationName)) {
        this.showMessageSnackbar(
          "Organization Name must be minimum 4 characters."
        );
        return false;
      } else if (
        !OrganizationNameRef.test(this.organization.OrganizationType)
      ) {
        this.showMessageSnackbar(
          "Organization Type must be minimum 4 characters."
        );
        return false;
      } else if (this.organization.ConfigID == "") {
        this.showMessageSnackbar("Organization Config ID Cannot be empty.");
      } else {
        return true;
      }
    },
    submit: function() {
      if (this.validation()) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function() {
      this.closeModal("ModalConfirmationOnSubmit");
      OrganizationService.addOrganization(this.organization)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(
            data.Message || "Oops! Something went wrong."
          );
          if (data.Status) {
            this.resetForm();
            this.$router.push({
              path: "/org-management/organization/all-orgs"
            });
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
</style>
